import { PaletteColor, PaletteColorOptions, ThemeOptions, createTheme } from "@mui/material";

declare module '@mui/material' {
  interface Palette {
    dangerPrimary: PaletteColor;
    dangerSecondary: PaletteColor;
  }
  interface PaletteOptions {
    dangerPrimary?: PaletteColorOptions;
    dangerSecondary?: PaletteColorOptions;
  }
}

declare module '@mui/material' {
  interface ChipPropsColorOverrides {
    dangerPrimary: true;
    dangerSecondary: true;
  }
}

const themeOptions: ThemeOptions = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#356B85',
    },
    secondary: {
      main: '#475467',
    },
    background: {
      default: '#fcfcfd',
    },
    text: {
      primary: '#101828',
      secondary: '#475467',
    },
    dangerPrimary: {
      main: '#d32f2f',
      light: '#e57373',
      dark: '#c62828',
      contrastText: '#ffffff',
    },
    dangerSecondary: {
      main: '#ef9a9a',
      light: '#f8bbd0',
      dark: '#d32f2f',
      contrastText: '#000000',
    },
  },
  components: {
    MuiCard: {
      styleOverrides: {
        root: {
          padding: '1rem',
        },
      },
    },
    MuiButton: {
      defaultProps: {
        size: "large",
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          minHeight: '36px',
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          padding: '0.5rem',
          minHeight: '36px',
        },
      },
    },
  },
});

export default themeOptions;