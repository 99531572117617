import { Paper, Stack, Typography } from '@mui/material';
import useTranslate from '../../../../hooks/useTranslate';
import { InterventionReportSummary } from '../../../../services/intervention-report.service';
import { AnimationType } from '../../../../store/slices/create-report-form.slice';

interface VisitReportsDataPaperProps {
  reports?: InterventionReportSummary[];
}

function VisitReportsDataPaper({ reports = [] }: VisitReportsDataPaperProps) {
  const { t, localeNumber } = useTranslate();

  const numberOfImplantations = reports.filter(report => report.isImplementation).length;
  const numberOfTrainings = reports.filter(report => report.isTraining).length;
  const numberOfShows = reports.filter(
    report => report.isAnimation && report.animationType === AnimationType.SHOW,
  ).length;
  const numberOfOpenDays = reports.filter(
    report => report.isAnimation && report.animationType === AnimationType.OPEN_DAY,
  ).length;
  const numberOfRoadShows = reports.filter(
    report => report.isAnimation && report.animationType === AnimationType.ROAD_SHOW,
  ).length;
  const numberOfTrainedPeople = reports.reduce((current, report) => {
    if (!report.isTraining) return current;
    return current + (report.numberOfTrainedPeople ?? 0);
  }, 0);
  const numberOfPrescriptions = reports.filter(report => report.isPrescription).length;

  const rows = [
    { label: t('dashboard.number_of_implementations'), value: numberOfImplantations },
    { label: t('dashboard.number_of_trainings'), value: numberOfTrainings },
    { label: t('dashboard.number_of_shows'), value: numberOfShows },
    { label: t('dashboard.number_of_open_days'), value: numberOfOpenDays },
    { label: t('dashboard.number_of_road_shows'), value: numberOfRoadShows },
    { label: t('dashboard.number_of_trained_people'), value: numberOfTrainedPeople },
    { label: t('dashboard.number_of_prescriptions'), value: numberOfPrescriptions },
  ];

  return (
    <Paper variant="outlined" sx={{ padding: 2, marginBottom: 2, flexGrow: 1 }}>
      <Typography variant="subtitle1" color="text.primary">
        {t('dashboard.totals')}
      </Typography>
      <Stack direction="column" spacing={0} sx={{ '& > :nth-of-type(even)': { backgroundColor: '#00000008' } }}>
        {rows.map(row => (
          <Stack key={row.label} direction="row" alignItems="center" justifyContent={'space-between'} px={1}>
            <Typography variant="body2" color="text.secondary">
              {row.label}
            </Typography>
            <Typography variant="h6" fontSize={18} color="text.primary">
              {localeNumber(row.value)}
            </Typography>
          </Stack>
        ))}
      </Stack>
    </Paper>
  );
}

export default VisitReportsDataPaper;
