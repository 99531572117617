import { Chip, Hidden, Stack, TableCell, TableRow, Typography } from '@mui/material';
import { getFullName } from '../../../../helpers/user';
import { InterventionReportSummary } from '../../../../services/intervention-report.service';
import useTranslate from '../../../../hooks/useTranslate';
import { useAppSelector } from '../../../../store';
import { UserRole } from '../../../../services/user.service';

export interface VisitReportRowProps {
  report: InterventionReportSummary;
}

function VisitReportRow({ report }: VisitReportRowProps) {
  const { t, localeDate } = useTranslate();
  const { user } = useAppSelector(state => state.auth);

  return (
    <TableRow
      component="a"
      sx={{
        '&:last-child td, &:last-child th': { border: 0 },
        textDecoration: 'none',
        userSelect: 'none',
      }}
      href={`/report/${report.id}`}>
      <TableCell>
        <Typography variant="body2">{localeDate(report.createdAt, 'short')}</Typography>
      </TableCell>
      <Hidden smDown>
        {user!.role !== UserRole.SALES_COORDINATOR && <TableCell>{getFullName(report.coordinator)}</TableCell>}
        <TableCell>{report.wholesaler.name}</TableCell>
        <TableCell>{report.wholesaler.zip}</TableCell>
        <TableCell>{report.wholesaler.city}</TableCell>
      </Hidden>
      <TableCell>
        <Stack direction="row" spacing={1}>
          {report.isImplementation && <Chip label={t('report.type.implementation')} size="small" color="primary" />}
          {report.isTraining && <Chip label={t('report.type.training')} size="small" color="primary" />}
          {report.isAnimation && (
            <Chip
              label={t('report.type.animation') + ' (' + t(`report.animation_type.${report.animationType!}`) + ')'}
              size="small"
              color="primary"
            />
          )}
          {report.isPrescription && <Chip label={t('report.type.prescription')} size="small" color="primary" />}
        </Stack>
      </TableCell>
    </TableRow>
  );
}

export default VisitReportRow;
