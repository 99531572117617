import { ChipOwnProps } from "@mui/material";
import { BenchmarkProposal, BenchmarkProposalProductAdjustment, BenchmarkProposalStatus, BenchmarkProposalSummary, ProductAdjustmentStatus } from "../services/benchmark-proposal.service";
import { UserRole, UserSummary } from "../services/user.service";
import { getManagers } from "./user";
import { searchWholesalers } from "./wholesaler";

export type ChipColor = ChipOwnProps['color'];

/**
 * Get the color of the status of a benchmark proposal.
 * @param status The status of the benchmark proposal.
 * @returns The color of the status.
 */
export function getStatusColor(status: BenchmarkProposalStatus): ChipColor {
  switch (status) {
    case BenchmarkProposalStatus.TO_NEGOCIATE:
      return 'info';
    case BenchmarkProposalStatus.ACCEPTED_BY_WHOLESALER:
      return 'secondary';
    case BenchmarkProposalStatus.ACCEPTED_BY_SALES_MANAGER:
      return 'warning';
    case BenchmarkProposalStatus.TO_BE_REVISED:
      return 'error';
    case BenchmarkProposalStatus.DONE:
      return 'success';
  }
}

/**
 * Get the color of the status of a product adjustment.
 * @param status The status of the product adjustment.
 * @returns The color of the status.
 */
export function getProductAdjustmentStatusColor(status: ProductAdjustmentStatus): ChipColor {
  switch (status) {
    case ProductAdjustmentStatus.KEEP:
      return 'success';
    case ProductAdjustmentStatus.MISSING:
      return 'dangerPrimary';
    case ProductAdjustmentStatus.RESTOCK:
      return 'dangerSecondary';
    case ProductAdjustmentStatus.OUT_OF_SALES_PLAN:
      return 'warning';
    case ProductAdjustmentStatus.REMOVE:
      return 'info';
    default:
      return 'secondary';
  }
}

/**
 * A comparison function for sorting product adjustments.
 * @param a The first product adjustment.
 * @param b The second product adjustment.
 * @returns A number indicating the order of the product adjustments.
 */
export function compareProductAdjustmentsByStatus(a: BenchmarkProposalProductAdjustment, b: BenchmarkProposalProductAdjustment): number {
  const statusOrder = [
    ProductAdjustmentStatus.KEEP,
    ProductAdjustmentStatus.RESTOCK,
    ProductAdjustmentStatus.MISSING,
    ProductAdjustmentStatus.OUT_OF_SALES_PLAN,
    ProductAdjustmentStatus.REMOVE,
    ProductAdjustmentStatus.UNKNOWN
  ];

  return statusOrder.indexOf(a.status) - statusOrder.indexOf(b.status);
}

/**
 * Get the min and max createdAt date of the benchmark proposals of the list.
 * @param benchmarkProposals The benchmark proposals to get the min and max date from.
 * @returns The min and max date of the benchmark proposals.
 */
export function getMinAndMaxDate(benchmarkProposals: (BenchmarkProposal | BenchmarkProposalSummary)[]): { minDate: Date, maxDate: Date } {
  if (benchmarkProposals.length === 0) {
    const aYearFromNow = new Date();
    aYearFromNow.setFullYear(aYearFromNow.getFullYear() + 1);
    return { minDate: new Date(0), maxDate: aYearFromNow };
  }

  let minDate: Date = new Date(benchmarkProposals[0].createdAt);
  let maxDate: Date = new Date(benchmarkProposals[0].createdAt);

  benchmarkProposals.forEach(item => {
    const createdAt = new Date(item.createdAt);
    if (createdAt < minDate) {
      minDate = createdAt;
    }
    if (createdAt > maxDate) {
      maxDate = createdAt;
    }
  });

  return { minDate, maxDate };
}

/**
 * Get the coordinators of a benchmark proposal.
 * @param proposal The benchmark proposal to get the coordinators from.
 * @returns The coordinators of the benchmark proposal.
 */
export function getProposalCoordinators(proposal: BenchmarkProposalSummary): UserSummary[] {
  let coordinators = getManagers(proposal.sales, UserRole.SALES_COORDINATOR);
  if (proposal.expectedCoordinator) {
    coordinators = [proposal.expectedCoordinator];
  }
  if (proposal.interventionReport) {
    coordinators = [proposal.interventionReport.coordinator];
  }

  return coordinators;
}

/**
 * Generates the title for the recap of the benchmark proposal.
 * @param proposal The benchmark proposal to get the title from.
 * @returns The title for the recap of the benchmark proposal.
 */
export function getProposalRecapTitle(proposal: BenchmarkProposal): string {
  const proposalDateForTitle = new Date(proposal.benchmarkStatement.createdAt)
    .toISOString()
    .split('T')[0]
    .replace(/-/g, '');

  return `${proposal.wholesaler.id}_${proposalDateForTitle}`;
}

/**
 * Search a list of benchmark proposals where the wholesaler matches the query.
 * @param query The query to search.
 * @param proposals The proposals to search in.
 * @returns The list of proposals that match the query.
 */
export function searchBenchmarkProposalsByWholesaler(query: string, proposals: BenchmarkProposalSummary[]): BenchmarkProposalSummary[] {
  const wholesalers = searchWholesalers(query, proposals.map(proposal => proposal.wholesaler));
  return proposals.filter(proposal => wholesalers.some(wholesaler => wholesaler.id === proposal.wholesaler.id));
}

export function formatAdjustmentQuantity(quantity: number, zeroSign: string = '-'): string {
  if (quantity === 0) return zeroSign;
  if (quantity > 0) return `+${quantity}`;
  return quantity.toString();
}

export function getAjustmentQuantityColor(quantity: number): string {
  if (quantity > 0) return 'success.main';
  if (quantity < 0) return 'error.main';
  return 'inherit';
}