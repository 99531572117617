import { useEffect, useState } from 'react';
import {
  AppBar,
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormGroup,
  IconButton,
  Stack,
  Tab,
  Tabs,
  TextField,
  Theme,
  Toolbar,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { useNavigate, useSearchParams } from 'react-router-dom';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import EditIcon from '@mui/icons-material/Edit';
import useTranslate from '../../../../hooks/useTranslate';
import { getProposalCoordinators, getStatusColor } from '../../../../helpers/benchmark-proposal';
import {
  BenchmarkProposal,
  BenchmarkProposalStatus,
  useUpdateScheduledInstallationDateMutation,
} from '../../../../services/benchmark-proposal.service';
import { getFullName } from '../../../../helpers/user';
import { UserRole } from '../../../../services/user.service';
import { useAppSelector } from '../../../../store';
import themeOptions from '../../../../theme';

export type ProposalTabType = 'report' | 'proposal' | 'layout' | 'statement' | 'deviation';

export interface ProposalHeaderProps {
  proposal: BenchmarkProposal;
  selectedTab?: ProposalTabType;
  onTabSelected?: (tab?: ProposalTabType) => void;
  onScheduledInstallationDateUpdated?: () => void;
}

function ProposalHeader({
  proposal,
  selectedTab,
  onTabSelected,
  onScheduledInstallationDateUpdated,
}: ProposalHeaderProps) {
  const isArchived = proposal.wholesaler.deletedAt !== null;

  const { t, localeDate } = useTranslate();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const [updateScheduledInstallationDate] = useUpdateScheduledInstallationDateMutation();

  const { user } = useAppSelector(state => state.auth);

  const [tabs, setTabs] = useState<{ value: ProposalTabType; label: string }[]>([]);
  const [showEditDateDialog, setShowEditDateDialog] = useState<boolean>(false);
  const [scheduledInstallationDate, setScheduledInstallationDate] = useState<string>(
    proposal.scheduledInstallationDate?.split('T')[0] ?? '',
  );

  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  function handleDateSubmit(evt: React.FormEvent<HTMLFormElement>) {
    evt.preventDefault();
    evt.stopPropagation();

    if (isArchived) {
      return;
    }

    if (scheduledInstallationDate) {
      updateScheduledInstallationDate({ benchmarkProposalId: proposal.id, date: scheduledInstallationDate })
        .unwrap()
        .then(() => {
          onScheduledInstallationDateUpdated?.();
          setShowEditDateDialog(false);
        })
        .catch(() => {});
    }
  }

  function handleTabSelected(tab: ProposalTabType) {
    setSearchParams(prev => {
      prev.set('tab', tab);
      return prev;
    });
  }

  useEffect(() => {
    const showReportTab =
      user!.role === UserRole.SALES_COORDINATOR &&
      (proposal?.state === BenchmarkProposalStatus.ACCEPTED_BY_SALES_MANAGER ||
        proposal?.state === BenchmarkProposalStatus.DONE);

    const newTabs: { value: ProposalTabType; label: string }[] = [];
    if (proposal.state !== BenchmarkProposalStatus.DONE) {
      if (showReportTab) {
        newTabs.push({ value: 'report', label: t('report.title') });
      }
      newTabs.push(
        {
          value: 'proposal',
          label: isProposalAcceptedOrDone ? t('benchmark_proposal.title_accepted') : t('benchmark_proposal.title'),
        },
        { value: 'layout', label: t('statement.layout_and_contact') },
        { value: 'statement', label: t('statement.title') },
      );
    } else {
      newTabs.push(
        { value: 'report', label: t('report.title') },
        { value: 'deviation', label: t('report.deviation_from_proposal') },
      );
    }

    setTabs(newTabs);
  }, [user, proposal]);

  useEffect(() => {
    const tabFromParams = searchParams.get('tab');
    const exists = tabs.some(tab => tab.value === tabFromParams);
    if (exists) {
      onTabSelected?.(tabFromParams as ProposalTabType);
    } else {
      onTabSelected?.(tabs[0]?.value);
    }
  }, [tabs, searchParams]);

  const salesCoordinators = getProposalCoordinators(proposal);
  const isProposalAcceptedOrDone =
    proposal.state === BenchmarkProposalStatus.ACCEPTED_BY_SALES_MANAGER ||
    proposal.state === BenchmarkProposalStatus.DONE;

  return (
    <>
      <AppBar position="relative" color="transparent" sx={{ backgroundColor: 'white', pt: 1 }}>
        <Toolbar>
          <Stack direction="column" spacing={1} flexGrow={1}>
            <Stack direction="row" alignItems="center" spacing={2}>
              <IconButton
                size="large"
                edge="start"
                color="inherit"
                aria-label="back"
                sx={{ mr: 1, p: 1 }}
                onClick={() => navigate('/')}>
                <ChevronLeftIcon />
              </IconButton>
              <Chip
                label={t(`benchmark_proposal.status.${proposal.state}`)}
                size="small"
                color={getStatusColor(proposal.state)}
              />
              <Typography
                variant={isMobile ? 'subtitle2' : 'subtitle1'}
                color="inherit"
                component="div"
                sx={{ flexGrow: 1 }}>
                {t('statement.date', { date: localeDate(proposal.benchmarkStatement.createdAt, 'short') })}
              </Typography>
            </Stack>
            <Stack direction={isMobile ? 'column' : 'row'} spacing={isMobile ? 1 : 4} pb={2}>
              <div>
                <Typography variant="caption" component="small" color="text.secondary">
                  {t('wholesaler.id')}
                </Typography>
                <Typography variant="body1" color={themeOptions.palette?.text?.primary}>
                  <Box component="a" href={`/wholesaler/${proposal.wholesaler.id}`} color="inherit">
                    {proposal.wholesaler.id}
                  </Box>
                </Typography>
              </div>
              <div>
                <Typography variant="caption" component="small" color="text.secondary">
                  {t('wholesaler.name')}
                </Typography>
                <Typography variant="body1">{proposal.wholesaler.name}</Typography>
              </div>
              <div>
                <Typography variant="caption" component="small" color="text.secondary">
                  {t('global.city')}
                </Typography>
                <Typography variant="body1">
                  {proposal.wholesaler.city} - {proposal.wholesaler.zip}
                </Typography>
              </div>
              {user?.role !== UserRole.SALES_COORDINATOR && (
                <div>
                  <Typography variant="caption" component="small" color="text.secondary">
                    {t('user.role.sales_coordinator')}
                  </Typography>
                  <Typography variant="body1">
                    {salesCoordinators.length > 0 ? salesCoordinators.map(c => getFullName(c)).join(' / ') : '-'}
                  </Typography>
                </div>
              )}
              {user?.role !== UserRole.SALES && (
                <div>
                  <Typography variant="caption" component="small" color="text.secondary">
                    {t('user.role.sales')}
                  </Typography>
                  <Typography variant="body1">{getFullName(proposal.sales)}</Typography>
                </div>
              )}
              <div>
                <Typography variant="caption" component="label" color="text.secondary" htmlFor="benchmark-date">
                  {t('benchmark_proposal.scheduled_intervention_date')}
                </Typography>
                <Stack direction="row" alignItems="center" spacing={1}>
                  <Typography variant="body1">
                    {proposal.scheduledInstallationDate
                      ? localeDate(proposal.scheduledInstallationDate, 'short')
                      : t('global.none')}
                  </Typography>
                  {!isArchived &&
                    user?.role === UserRole.SALES_COORDINATOR &&
                    proposal.state === BenchmarkProposalStatus.ACCEPTED_BY_SALES_MANAGER && (
                      <IconButton
                        size="small"
                        color="secondary"
                        aria-label="edit"
                        onClick={() => setShowEditDateDialog(true)}>
                        <EditIcon fontSize="small" />
                      </IconButton>
                    )}
                </Stack>
              </div>
              <div>
                <Typography variant="caption" component="small" color="text.secondary">
                  {t('layout.benchmark')}
                </Typography>
                <Typography variant="body1">{proposal.benchmarkStatement.benchmarkLayout.name}</Typography>
              </div>
            </Stack>

            <Box sx={{ marginTop: 1 }}>
              {tabs.length > 0 && Boolean(selectedTab) && (
                <Tabs
                  orientation={isMobile ? 'vertical' : 'horizontal'}
                  value={selectedTab}
                  onChange={(_, value) => handleTabSelected(value)}
                  aria-label="markets">
                  {tabs.map(tab => (
                    <Tab
                      key={tab.value}
                      value={tab.value}
                      label={tab.label}
                      wrapped
                      sx={{ maxWidth: isMobile ? 'none' : undefined }}
                    />
                  ))}
                </Tabs>
              )}
            </Box>
          </Stack>
        </Toolbar>
      </AppBar>

      <Dialog
        open={showEditDateDialog}
        onClose={() => setShowEditDateDialog(false)}
        maxWidth="xs"
        fullWidth
        aria-labelledby="edit-date-title"
        aria-describedby="edit-date-description">
        <form onSubmit={handleDateSubmit}>
          <DialogTitle id="edit-date-title">{t('benchmark_proposal.scheduled_intervention_date')}</DialogTitle>
          <DialogContent>
            <FormGroup sx={{ mt: 2 }}>
              <TextField
                type="date"
                id="benchmark-date"
                variant="outlined"
                value={scheduledInstallationDate}
                onChange={evt => setScheduledInstallationDate(evt.target.value)}
                InputProps={{ inputProps: { min: new Date().toISOString().split('T')[0] } }}
                fullWidth
                required
              />
            </FormGroup>
          </DialogContent>
          <DialogActions>
            <Button type="button" variant="outlined" onClick={() => setShowEditDateDialog(false)}>
              {t('global.cancel')}
            </Button>
            <Button type="submit" variant="contained">
              {t('global.confirm')}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
}

export default ProposalHeader;
